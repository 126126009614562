<template>
  <v-card elevation="0" rounded="0">
    <div class="calendar-container">
      <v-row dense class="days-row mt-1">
        <v-col v-for="(day, index) in days" :key="index" cols="auto" class="day-col">
          <div :class="['day-wrapper', { selected: day.selected, disabled: day.disabled }]">
            <div :class="['circle', { selected: day.selected }]" @click="!day.selected ? updateValue(day) : null"
              v-ripple class="rounded-lg">
              <span class="day-letter">{{ day.day }}</span>
              <div class="indicators">
                <v-icon v-if="day.completed" color="success" size="16" class="check-icon overflow-hidden"
                  style="background-color:white; border-radius:50%;">
                  mdi-check-circle
                </v-icon>
                <div v-else-if="day.isToday" class="dot primary"></div>
                <div v-else-if="day.scheduled" class="dot scheduled"></div>
              </div>
            </div>
            <div class="day-number">{{ day.date }}</div>
          </div>
        </v-col>
      </v-row>
    </div>

    <v-progress-linear absolute indeterminate v-if="loading" color="primary" height="2">
    </v-progress-linear>
  </v-card>
</template>

<script>
import moment from "moment";

export default {
  data() {
    return {
      days: [],
      currentDay: moment().locale('es'),
      dates: null,
      today: moment().format("YYYY-MM-DD"),
      model: null,
    };
  },

  created() {
    this.buildDaysArray();

    let dates = [];
    for (let i = 2; i > 0; i--) {
      dates.push(moment().subtract(i, "days").format("YYYY-MM-DD"));
    }
    dates.push(moment().format("YYYY-MM-DD"));
    for (let i = 1; i < this.maxDays; i++) {
      dates.push(moment().add(i, "days").format("YYYY-MM-DD"));
    }
    this.dates = dates;
  },

  mounted() {
    this.scrollToActiveDay();
  },

  methods: {
    buildDaysArray() {
      const today = moment().locale('es');
      const days = [];

      for (let i = 5; i > 0; i--) {
        let dayMoment = today.clone().subtract(i, "days");
        days.push({
          day: dayMoment.format("dd")[0],
          date: dayMoment.date(),
          moment: dayMoment,
          completed: this.isWorkoutDay(dayMoment),
          scheduled: this.isScheduledDay(dayMoment),
          selected: false,
          disabled: false,
        });
      }

      days.push({
        day: today.format("dd")[0],
        date: today.date(),
        moment: today,
        completed: this.isWorkoutDay(today),
        scheduled: this.isScheduledDay(today),
        selected: true,
        disabled: false,
        isToday: true,
      });
      /* 
            let futureDay = today.clone().add(1, "days");
            days.push({
              day: futureDay.format("dd")[0],
              date: futureDay.date(),
              moment: futureDay,
              completed: this.isWorkoutDay(futureDay),
              scheduled: this.isScheduledDay(futureDay),
              selected: false,
              disabled: false,
            }); */

      this.days = days;
    },

    isWorkoutDay(dayMoment) {
      let dayStr = dayMoment;

      if (this.workouts.length > 0) {
        return this.workouts.some((workout) => {
          return moment(new Date(workout.date.seconds * 1000)).isSame(dayStr, 'day');
        });
      } else {
        return false;
      }
    },

    isScheduledDay(dayMoment) {
      return this.schedules.some((schedule) => {
        return schedule.isSame(dayMoment, 'day');
      });
    },

    formatDate(date, format) {
      let now = moment();
      if (moment(date).isSame(now, 'day') && format === 'ddd') {
        return "Hoy";
      }
      return moment(date).locale("es").format(format);
    },
    updateValue(date) {
      if (this.loading) return;
      if (this.value === date) return;

      this.$emit('input', date.moment.format("YYYY-MM-DD"));

      this.currentDay = date.moment;

      this.days.forEach((day) => {
        day.selected = false;
      });

      date.selected = true;
    },
    scrollToActiveDay() {
      let index = this.dates.indexOf(this.value);
      if (index === -1) return;

      this.model = index;
    },

    checkCompletedDays() {
      this.days.forEach((day) => {
        day.completed = this.isWorkoutDay(day.moment);
        day.scheduled = this.isScheduledDay(day.moment);
      });
    },
  },

  props: {
    workouts: {
      type: Array
    },
    value: String,
    maxDays: {
      type: Number,
      default: 3,
    },
    loading: Boolean,
    unableInteraction: Boolean,
    disabledWeekDays: {
      type: Array,
      default: () => [],
    },

    schedules: {
      type: Array
    },
  },

  watch: {
    workouts: {
      handler: function () {
        this.checkCompletedDays();
      },
    },
    schedules: {
      handler: function () {
        this.checkCompletedDays();
      },
    },
  }
};
</script>
<style scoped>
.calendar-container {
  padding: 16px;
  font-family: "Roboto", sans-serif;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.date {
  font-size: 18px;
  font-weight: bold;
}

.calendar-text {
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

.days-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.day-col {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.day-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.circle {
  width: 48px;
  height: 48px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  position: relative;
  cursor: pointer;
  transition: all 0.2s ease;
}

.circle:hover {
  transform: translateY(-2px);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.circle.selected {
  background-color: var(--v-primary-base);
  color: white;
  transform: translateY(-2px);
  border: 1px solid rgba(33, 150, 243, 0.2);
}

.day-letter {
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 2px;
}

.day-number {
  font-size: 14px;
  font-weight: 500;
}

.indicators {
  position: absolute;
  top: -6px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.check-icon {
  margin-top: -2px;
}

.dot {
  width: 6px;
  height: 6px;
  border-radius: 50%;
}

.dot.scheduled {
  background-color: var(--v-warning-base);
}

.dot.primary {
  background-color: var(--v-primary-base);
}

.theme--dark .circle {
  background-color: rgba(255, 255, 255, 0.05);
}

.theme--light .circle {
  background-color: rgba(0, 0, 0, 0.03);
}

.day-wrapper.disabled {
  opacity: 0.5;
  pointer-events: none;
}
</style>

<style>
.v-badge--dot .v-badge__badge {
  inset: calc(95% - 12px) auto auto calc(50% - 2px) !important;
}
</style>
